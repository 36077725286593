import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LayoutView from '@/layout/LayoutView.vue';

// 对RouteRecordRaw类型进行扩展
export type AddRouteRecordRaw = RouteRecordRaw & {
  hidden?: boolean;
  sort?: number;
};

const routes: Array<AddRouteRecordRaw> = [
  {
    path: '',
    name: 'Index',
    redirect: '/console',
  },
  {
    path: '/console',
    name: 'Console',
    component: LayoutView,
    //重定向默认显示第一个children路由
    redirect: '/user',
    children: [
      {
        path: '/user',
        component: () => import('@/views/user/UserView.vue'),
        name: 'User',
        meta: { title: '用户管理', icon: 'UserFilled', affix: true },
      },
      {
        path: '/exam',
        component: () => import('@/views/exam/ExamView.vue'),
        name: 'Exam',
        meta: { title: '题库上传', icon: 'UploadFilled', affix: true },
      },
      {
        path: '/video',
        component: () => import('@/views/video/VideoView.vue'),
        name: 'Video',
        meta: { title: '视频上传', icon: 'Promotion' },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error/NotPage.vue'),
    hidden: true,
  },
];

export function consoleRoutes() {
  const consoleRoutes = routes.filter((item) => {
    return item.name == 'Console';
  });
  const childs = consoleRoutes[0];
  if (childs.children) {
    return childs.children.filter((item: AddRouteRecordRaw) => {
      return !item.hidden;
    });
  }
  return [];
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...routes] as AddRouteRecordRaw[],
});

export default router;
